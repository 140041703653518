@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $white;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: "mrs-eaves-xl-serif", serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	background-color: $white;
	color: $black;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

h1,h2,h3,h4,h5,ul,ol, p{
	margin: 0;
}

p{
	font-size: 16px;
	line-height: 1.4;
}

.hide{
	display: none;
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.split{
	@include flex(column,initial,initial);
	.side{
		flex: 1;
	}
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

header {

}

main {
	>.container{
		min-height: calc(100vh - 130px);
		@include flex(column,center,center);
		gap: 20px;
		h2{
			font-size: 40px;
			text-align: center;
		}
		.main-image{
			width: 300px;
		}
	}
	@include tablet-up{
		>.container{
			min-height: calc(100vh - 80px);
			h2{
				font-size: 100px;
			}
			.main-image{
				width: 900px;
			}
		}
	}
	.popup{
		transition: all .4s;
		opacity: 0;
		pointer-events: none;
		position: fixed;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		background-color: #f8f4edb3;
		transform: scale(0.5);
		.container{
			min-height: 100vh;
			@include flex(column,center,center);
			.wrapper{
				width: 100%;
				position: relative;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				width: 280px;
				height: 400px;
				.close{
					border: none;
					padding: 0;
					line-height: 1;
					font-size: 30px;
					background-color: transparent;
					position: absolute;
					top: 10px;
					right: 10px;
					i{
						font-weight: 400;
					}
				}
				.content{
					.slider{
						position: absolute;
						a{
							img{
								display: block;
								margin-bottom: 5px;
							}
							p{
								text-align: center;
								font-size: 16px;
							}
						}
						.slick-arrow{
							position: absolute;
							top: calc(50% - 40px);
							top: calc(50% - 30px);
							font-size: 40px;
							font-size: 30px;
							background-color: transparent;
							color: #000;
							border: none;
							padding: 0;
							cursor: pointer;
							z-index: 99;
							&.slick-prev{
								left: -40px;
								left: -30px;
							}
							&.slick-next{
								right: -40px;
								right: -30px;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			pointer-events: all;
			transform: scale(1.0);
		}

		&#music{
			.container{
				.wrapper{
					background-image: url('../../dist/img/bg-music.png');
					.close{
						top: 60px;
						right: 40px;
					}
					.content{
						.slider{
							width: 140px;
							left: calc(50% - 70px);
							top: 130px;
						}
					}
				}
			}
		}

		&#videos{
			.container{
				.wrapper{
					background-image: url('../../dist/img/bg-videos.png');
					.close{
						top: 90px;
						right: 30px;
					}
					.content{
						.slider{
							width: 150px;
							left: calc(50% - 60px);
							top: 150px;
						}
					}
				}
			}
		}

		&#newsletter{
			.container{
				.wrapper{
					background-image: url('../../dist/img/bg-mail.png');
					height: 180px;
					.close{
						top: 20px;
						right: 20px;
						font-size: 25px;
					}
					.content{
						position: absolute;
						width: 170px;
						left: calc(50% - 80px);
						bottom: 16px;
						form{
							overflow: hidden;
							label{
								position: absolute;
								left: -99999px;
								opacity: 0;
								pointer-events: none;
							}
							input{
								width: 100%;
								border: none;
								color: $black;
								text-align: center;
								font-size: 16px;
								background-color: transparent;
								&::placeholder{
									color: $black;
								}
							}
							.form-group{
								margin-bottom:20px;
							}
							button{
								border: solid 2px $black;
								text-transform: uppercase;
								font-weight: 700;
								margin-left: auto;
								display: block;
								padding: 4px 10px;
								background-color: transparent;
							}
						}
						.form-alert{
							text-align: center;
							display: none;
							&.error{
								position: absolute;
								bottom: -80px;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
		
		@include tablet-up{
			.container{
				.wrapper{
					width: 460px;
					height: 520px;
					.content{
						.slider{
							a{
								img{
									display: block;
									margin-bottom: 10px;
								}
								p{
									font-size: 20px;
									line-height: 1;
								}
							}
						}
					}
				}
			}

			&#music{
				.container{
					.wrapper{
						.close{
							top: 40px;
							right: 70px;
						}
						.content{
							.slider{
								width: 200px;
								position: absolute;
								left: calc(50% - 100px);
								top: 160px;
							}
						}
					}
				}
			}

			&#videos{
				.container{
					.wrapper{
						width: 540px;
						.close{
							top: 40px;
							right: 50px;
						}
						.content{
							.slider{
								width: 300px;
								position: absolute;
								left: calc(50% - 130px);
								top: 160px;
							}
						}
					}
				}
			}

			&#newsletter{
				.container{
					.wrapper{
						width: 540px;
						height: 340px;
						.close{
							top: 40px;
							right: 50px;
						}
						.content{
							width: 300px;
							position: absolute;
							left: calc(50% - 130px);
							bottom: 50px;							
							form{
								overflow: hidden;
								.form-group{
									margin-bottom:20px;
								}
								input{
									padding: 10px;
								}
							}
						}
					}
				}
			}

		}
	}
}

footer {
	padding: 10px 0;
	.container{
		@include flex(column,center,center);
		gap: 20px;
		.copyright{
			text-align: center;
			img{
				display: inline-block;
				height: 16px;
				margin-bottom: 5px;
			}
			p{
				font-size: 8px;
				font-family: Arial, Helvetica, sans-serif;
				a{
					@include hover-focus{
						text-decoration: underline;
					}
				}
			}
		}
	}
	@include tablet-up{
		.container{
			@include flex(row,space-between,flex-end);
			.copyright{
				text-align: left;
				flex: 2;
			}
			.social{
				flex: 1;
				max-width: 1;
				justify-content: flex-end;
			}
		}
	}
}